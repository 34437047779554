<template>
    <div class="section-2">
        <div class="container">
            <div class="row p-0 m-0">
                <div class="col-12 col-lg-12">
                    <router-link to="/" class="">
                        <div class="fl-x-cl mt-4">
                            <img class="logo" src="../assets/web/login/logoo.png">
                        </div>
                    </router-link>
                    <div class="dg-sm-mt" align="center">
                        <p class="font-jost-book  fs-4 mb-2 mg margin-1">Welcome to KLDB Training Programs</p>
                        <p class="font-jost-semi-bold fs-lg-9 mb-0">Registration Portal</p>
                        <p @click="$router.push('/login/')" class="font-inter-regular text-white-2 fs--1 pb-4">Already
                            have an Account ?
                            <a href="#" class="line-bottom text-decoration-none"> Sign in</a></p>

                        <div class="px-lg-9">
                            <p @click="$router.push('/register/')" class="font-lato-semi-bold ml-4 user-reg-in"
                               align="left">Basic Information</p>
                            <b-form :save-url="registerUrl" @success="formSuccess" v-slot="{model, loading}"
                                    :save-params="{user_type:'Organization'}">
                                <div class="row">

                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Organization Name*" border-radius="0"
                                                         name="Organization Name" id="Organization Name"
                                                         v-model="model.organization_name" :disabled="loading"
                                                         :rules="{ required:true}"
                                                         class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Create A Password" border-radius="0"
                                                         name="Password" type="password" id="Password"
                                                         v-model="model.password" :disabled="loading"
                                                         class=" place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-vue-select border-radius="0" size="sm" name="Organization Type"
                                                              class="mb-3 c-input-select-b-white-1 place-holder-white c-input py-1"
                                                              aria-placeholder=" Type Organization"
                                                              :disabled="loading"
                                                              :options="typeOptions" v-model="model.organization_type"
                                                              placeholder="Type Of Organization"></validated-vue-select>
                                    </div>

                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Confirm New Password" border-radius="0"
                                                         name="Confirm Password" type="password" id="Confirm Password"
                                                         v-model="model.confirm_password" :disabled="loading"
                                                         class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Designation" border-radius="0"
                                                         name="Designation" id="Designation"
                                                         v-model="model.designation" :disabled="loading"
                                                         class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="House/ Flat No./ Building/Apartment"
                                                         border-radius="0" name="Address" id="Address"
                                                         class="py-1 place-holder-white c-input"
                                                         v-model="model.address" :disabled="loading">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Name" border-radius="0"
                                                         name="Name"
                                                         id="name" v-model="model.name" :disabled="loading"
                                                         class="py-1 place-holder-white c-input bdr-radius">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Area/Street/Village" border-radius="0"
                                                         name="Area" id="Area" v-model="model.area"
                                                         :disabled="loading" class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Phone Number" border-radius="0"
                                                         name="Phone Number" type="number" id="Phone"
                                                         v-model="model.phone_number" :rules="{max: 10, min: 10}"
                                                         :disabled="loading" class="num-hide py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Town/City" border-radius="0"
                                                         name="City" id="City" v-model="model.city"
                                                         :disabled="loading" class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>

                                    <div class="col-lg-6">
                                        <validated-input size="sm" placeholder="Email Id" border-radius="0"
                                                         name="Email" type="email" v-model="model.email"
                                                         :disabled="loading"
                                                         class="py-1 place-holder-white c-input">
                                        </validated-input>
                                    </div>

                                    <div class="col-lg-3 pr-lg-0 mr-lg-0">
                                        <validated-ajax-vue-select border-radius="0" size="sm" name="State"
                                                                   class="mb-3 c-input-select-b-white-1 place-holder-white c-input py-1"
                                                                   aria-placeholder="Select State"
                                                                   :disabled="loading" :url="stateOptions"
                                                                   v-model="model.state" placeholder="State"/>
                                    </div>
                                    <div class="col-lg-3 ">
                                        <validated-input size="sm" name="Pincode" placeholder="Pincode"
                                                         border-radius="0" v-model="model.pincode" :disabled="loading"
                                                         type="number" class="  num-hide py-1 p-0 place-holder-white c-input">
                                        </validated-input>
                                    </div>
                                </div>
                                <btn border-radius="0" class="px-7 mt-43 py-21 mb-4 bdr-radius" text="Register"></btn>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import urls from '../data/urls';

export default {
    name: 'RegisterOrganization',
    data () {
        return {
            stateOptions: urls.masterData.State.stateOptions,
            registerUrl: urls.auth.register,
            typeOptions: [
                { label: 'Type 1', value: 'Type 1' },
                { label: 'Type 2', value: 'Type 2' }
            ],
            genderOptions: [
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' },
                { value: 'O', label: 'Others' }
            ],
            maritalStatusOptions: [
                { label: 'Married', value: 'Married' },
                { label: 'Unmarried', value: 'Unmarried' },
                { label: 'Divorced', value: 'Divorced' }
            ]
        };
    },
    methods: {
        formSuccess () {
            this.$notify('Registered Successfully', 'Success', {
                type: 'success'
            });
            this.$router.push('/login/');
        }
    }
};
</script>

<style scoped>
.mg {
    margin-top: -48px;
}

.num-hide {
    -webkit-appearance: none;
}

.section-2 {
    background-image: url("../assets/web/register/bgi.png");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.container {
    color: white;
}

.icon-set {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.place-holder-white {

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
}

}
.bdr-radius {
    border-radius: 2px;
}

.container {
    color: white;
}

.i-icon {
    position: absolute;
    top: 56px;
    right: -8px;
}

.logo {
    height: 78px;
    width: 148px;
}

.fb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.mg {
    margin-top: -48px;
}

@media (max-width: 750px) {
    .margin-1 {
        margin-top: 40px;
    }
}
</style>
